<template>
  <div class="login-page">
    <div class="login-wrapper">
      <el-card>
        <div class="login-form">
          <div class="login-title">登录</div>
          <el-form ref="form" :model="loginForm">
            <el-form-item>
              <el-input v-model="loginForm.username" type="text" placeholder="用户名" />
            </el-form-item>
            <el-form-item>
              <el-input v-model="loginForm.password" type="password" placeholder="密码" />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleLogin">登录</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    data() {
      return {
        loginForm: {
          username: '',
          password: ''
        }
      }
    },
    methods: {
      handleLogin() {
        const { username, password } = this.loginForm
        if (username.length === 0) {
          this.$notify.warning('用户名不能为空')
          return
        }
        if (password.length === 0) {
          this.$notify.warning('密码为空')
          return
        }
        axios.post('/api/login', { username, password }).then(data => {
          if (data.data === 'success') {
            this.$notify.success('登录成功')
            this.$router.push('/index')
          } else {
            this.$notify.error('登录失败')
          }
        })
      }
    }
  }
</script>

<style lang="less">
  .login-page {
    width: 100%;
    height: 100%;
    background-color: rgba(127, 187, 255, 0.99);
    display: flex;
    justify-content: center;
    align-items: center;
    
    .login-wrapper {
      width: 300px;
      background-color: #ffffff;
      
      .login-form {
        .login-title {
          text-align: center;
          border-bottom: 1px solid #e8e8e8;
          padding-bottom: 16px;
          margin-bottom: 16px;
        }
      }
    }
  }
</style>
